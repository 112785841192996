// equivalent to jqeury document ready
const domReady = callback => {
  document.readyState === 'interactive' || document.readyState === 'complete' ? callback() : document.addEventListener('DOMContentLoaded', callback);
};

domReady(() => {
  // Parallax
  //var parallax = $('.parallax').SquizParallax();

  /*
   * Hero Image Load
   * Uses imageLoad plugin installed from bower.
   *
   * @author Ryan <ryu@squiz.net>
   * @date   March 2017
   */
  //const $heroSection = document.querySelectorAll( '.p-hero--animation' );
  const $heroAnimation = Array.from( document.querySelectorAll( '.p-hero--animation' ) );
  //const $heroImag = Array.from( document.querySelectorAll( '.p-hero--animation') );

  $heroAnimation.map(function(e, i){
    //console.log(value, index);
    let $heroImg = e.querySelector( '.p-hero__img-wrapper' );
    var heroImgLoad = imagesLoaded( $heroImg );

    heroImgLoad.on( 'progress', ( instance, image ) => {
      e.classList.add( 'is-hero-image-loaded' );
    });
  });
});
