(function() {

  // add class to element
  // http://youmightnotneedjquery.com/#add_class
  const addClass = (element, className) => {
    if (element.classList) {
      element.classList.add(className);
    }
    else {
      element.className += '' + className;
    }
  };

  // remove class from element
  // http://youmightnotneedjquery.com/#remove_class
  const removeClass = (element, className) => {
    let regex = new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi');
    if (element.classList) {
      element.classList.remove(className);
    }
    else {
      element.className = element.className.replace(regex, ' ');
    }
  };

  // Detect if table is scrolled to the right end
  // Add/Remove class 'is-end' to indicate
  const isTblScrolledToRight = () => {
    const containers = document.querySelectorAll('.e-tbl__inner');

    for (let i = 0; i < containers.length; i += 1) {
      let container = containers[i],
        containerParent = container.parentElement,
        containerWidth = container.offsetWidth,
        tbl = container.getElementsByTagName('table')[0];

      container.onscroll = () => {
        let tblRect = tbl.getBoundingClientRect();

        if (tblRect.right <= containerWidth) {
          addClass(containerParent, 'is-end');
        }
        else {
          removeClass(containerParent, 'is-end');
        }
      };
    }
  };

  // Check if table is wrapped
  const isTblWrapped = (tbl) => {
    if (tbl.parent.classList.contains('e-tbl__inner')) {
      return true;
    }

    return false;
  };

  // clone classes from source element to destination element
  const cloneClassList = (srcEle, destEle) => {
    for(let i = 0; i < srcEle.classList.length; i += 1) {
      destEle.classList.add(srcEle.classList[i]);
    }
  };

  // Unwrap table
  const unwrapTbl = (tbl) => {
    // Quit, if table is not wrapped
    if (!isTblWrapped(tbl)) {
      return;
    }

    // find table outer wrapper
    let tblOuterWrapper = tbl.parent.parentElement;

    // Quit, if the table is not correctly wrapped
    if (!tblOuterWrapper.classList.contains('e-tbl__wrapper') ||
      !tbl.parent.classList.contains('e-tbl__inner')) {
      return;
    }

    // Find table caption, create new element
    let tblCaption = tblOuterWrapper.querySelectorAll('.e-tbl__caption')[0],
      newTbl = document.createElement('table');

    // Clone classes from original table to new one
    cloneClassList(tbl.table, newTbl);

    // If table has caption, insert caption into newly created table node
    if (tblCaption) {
      tblCaption = `<caption>${tblCaption.innerHTML}</caption>`;
      newTbl.insertAdjacentHTML('afterbegin', tblCaption);
    }

    // Insert table content into newly created table node
    newTbl.insertAdjacentHTML('beforeend', tbl.table.innerHTML);
    // Append new node in front of wrapped table, and remove wrapped one
    tblOuterWrapper.parentElement.insertBefore(newTbl, tblOuterWrapper);
    tblOuterWrapper.parentElement.removeChild(tblOuterWrapper);
  };

  // Wrap table
  const wrapTbl = (tbl) => {
    // Create outer wrapper element and new html content
    let tblCaption = tbl.table.getElementsByTagName('caption')[0],
      tblOuterWrapper = document.createElement('div'),
      newInnerHTML = `<div class="e-tbl__inner">${tbl.table.outerHTML}</div>`;

    // If table has a caption
    // Put table caption outside inner wrapper so that it would stay in viewport
    if (tblCaption) {
      // remove original caption
      tbl.table.removeChild(tblCaption);
      // put caption content in '.e-tbl__caption' container
      newInnerHTML = `<div class="e-tbl__caption">${tblCaption.innerHTML}</div>
        <div class="e-tbl__inner">${tbl.table.outerHTML}</div>`;
    }

    // Insert wrapped table before original table, and remove original one
    tblOuterWrapper.className = 'e-tbl__wrapper';
    tbl.parent.insertBefore(tblOuterWrapper, tbl.table);
    tblOuterWrapper.innerHTML = newInnerHTML;
    tbl.parent.removeChild(tbl.table);
  };

  const isTblOverflow = (tbl) => {
    if (tbl.table.offsetWidth > tbl.parent.offsetWidth) {
      return true;
    }

    return false;
  }

  // Check if table is valid
  // * table doesn't have classes to disable scroll
  // * table is not wrapped
  const isValidTbl = (tbl) => {
    if (tbl.table.classList.contains('no-tbl-scrollable')) {
      return false;
    }

    return true;
  };

  // Make tables responsive
  const handleResponsiveTbls = (event) => {
    // Find all tables
    const tbls = document.querySelectorAll('table');

    // Stop if there were no tables inside html document
    if (!tbls) {
      return;
    }

    // Check tables
    for (let i = 0; i < tbls.length; i += 1) {
      // let tbl = tbls[i],
      //   tblParent = tbl.parentElement;
      let tbl = {
        'table': tbls[i],
        'parent': tbls[i].parentElement
      };

      // Skip invalid tables
      if (!isValidTbl(tbl)) {
        continue;
      }

      // Table overflows and not wrapped, needs to be wrapped
      if (isTblOverflow(tbl) && !isTblWrapped(tbl)) {
        wrapTbl(tbl);
      }

      // Table not overflow and wrapped, needs to be unwrapped
      if (!isTblOverflow(tbl) && isTblWrapped(tbl)) {
        unwrapTbl(tbl);
      }
    }

    // Show/Hide right end gradient mask by adding/removing class
    isTblScrolledToRight();
  };

  // Start
  const init = () => {
    // Make tables responsive
    window.onload = () => {
      handleResponsiveTbls('load');
    }

    window.onresize = () => {
      handleResponsiveTbls('resize');
    }
  };

  // Start
  init();
})();