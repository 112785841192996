(() => {
  const $window = $(window),
    $sliders = $('.js-carousel'),
    defaultSettings = {
      arrows: true,
      autoplay: true,
      autoplaySpeed: 2000,
      centerMode: false,
      cssEase: 'cubic-bezier(.77, 0, .175, 1)',
      dots: true,
      infinite: true,
      prevArrow: `<button type="button" class="slick-prev slick-arrow">
      <span class="slick-arrow__txt">Previous</span></button>`,
      nextArrow: `<button type="button" class="slick-next slick-arrow">
      <span class="slick-arrow__txt">Next</span></button>`,
      slidesToShow: 3,
      slidesToScroll: 3,
      speed: 400,
      responsive: [
        {
          breakpoint: 900,
          settings: {
            centerMode: false,
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    },
    mqSmall = '(max-width: 599px)',
    mqMedium = '(min-width: 600px)',
    mqWide = '(min-width: 960px)',
    mqExtraWide = '(min-width: 1200px)';


  const isInteger = (val) => {
    if ($.isNumeric(val) && Math.floor(val) === val) {
      return true;
    }

    return false;
  }

  const getSliderSettings = ($ele) => {
    let settings = $.extend({}, defaultSettings),
      options = {
        arrows: $ele.data('arrows'),
        autoplay: $ele.data('autoplay'),
        autoplaySpeed: $ele.data('autoplayspeed'),
        centerMode: $ele.data('centermode'),
        dots: $ele.data('dots'),
        slidesToShow: $ele.data('slidestoshow'),
        speed: $ele.data('speed')
      };

    if (options.arrows === 'off') {
      settings.arrows = false;
    }

    if (options.autoplay === 'off') {
      settings.autoplay = false;
    }

    if (options.autoplaySpeed) {
      if (isInteger(options.autoplaySpeed)) {
        settings.autoplaySpeed = options.autoplaySpeed;
      }
    }

    if (options.centerMode === 'on') {
      settings.centerMode = true;
    }

    if (options.dots === 'off') {
      settings.dots = false;
    }

    if (options.slidesToShow) {
      if (isInteger(options.slidesToShow)) {
        settings.slidesToShow = options.slidesToShow;
      }
    }

    if (options.speed) {
      if (isInteger(options.speed)) {
        settings.speed = options.speed;
      }
    }

    return settings;
  };

  const createSlick = ($ele, settings) => {
    if (!$ele.hasClass('slick-initialized')) {
      $ele.slick(settings);
    }
  };

  const destroySlick = ($ele) => {
    if ($ele.hasClass('slick-initialized')) {
      $ele.slick('unslick');
    }
  };

  const deactiveSliders = () => {
    if (!$sliders) {
      return;
    }

    $sliders.map((i) => {
      let $slider = $($sliders[i]);
      destroySlick($slider);
    });
  };

  const activeSliders = () => {
    if (!$sliders) {
      return;
    }

    $sliders.map((i) => {
      let $slider = $($sliders[i]),
        settings = getSliderSettings($slider);

      createSlick($slider, settings);
    });
  };

  const initSliders = () => {
    $window.on('load resize', () => {
      if (Modernizr.mq(mqSmall)) {
        deactiveSliders();
      }
      else {
        activeSliders();
      }
    });
  }

  const init = () => {
    initSliders();
  }

  init();
})(jQuery);
