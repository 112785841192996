// (function() {
//
//   /**
//   MEDIA DISPLAY JS FUNCTION
//   */
//   const ERRORS = {
//     '34': 'Connection error. Please review site social media configuration.',
//     generic: 'Unknown error.'
//   };
//
//   const mediaDetail = {
//     render: function(data, template) {
//       var listing = template,
//           form = '',
//           noResults = '',
//           template  = null;
//       //Twitter error handling
//       try {
//         if(data.items.errors.length > 0) {
//             this.processErrors(data.items.errors);
//             return;
//         }
//       } catch (error) {}
//
//       //Facebook error handling
//       try {
//         if(data.items.hasOwnProperty('error')) {
//           print(this.getErrorElement(ERRORS['34']));
//         }
//       } catch(error) {}
//
//       //compile data to template
//       try {
//         let items = data;
//         template = Handlebars.compile(listing.html());
//
//
//         console.log(items);
//         console.log(template(items));
//
//         $('.o-container').append(template(items));
//         console.log('work');
//       } catch (error) {
//         console.log(this.getErrorElement(error));
//         console.log("Error:");
//         template = Handlebars.compile(noResults);
//         console.log(template(data));
//         console.log('not work');
//       }
//     },
//
//     getErrorElement: function(errorMsg) {
//       return ['<span>', errorMsg, '</span>'].join('');
//     }
//   };
//
//
//   /**
//   CUSTOM HANDLERBAR HELPER JS FUNCTION
//   */
//   Handlebars.registerHelper('checkAuthor', function(data) {
//     if(data.retweeted_status == null) {
//       return data.user.name;
//     }else {
//       if(data.entities.user_mentions.length > 0){
//         return data.entities.user_mentions[0].name;
//       } else {
//         return data.user.name;
//       }
//     }
//   });
//
//   Handlebars.registerHelper('checkImage', function(data) {
//     if(data.retweeted_status != null){
//       return data.retweeted_status.user.profile_image_url.replace('http','https');
//     } else {
//       return data.user.profile_image_url.replace('http','https');
//     }
//   });
//
//   Handlebars.registerHelper('checkAccount', function(data) {
//     if(data.retweeted_status != null){
//       return data.retweeted_status.user.screen_name;
//     } else {
//       return data.user.screen_name;
//     }
//     // return user.screen_name;
//   });
//
//   Handlebars.registerHelper('createdDate', function(data) {
//     let monthNames = [
//         "January", "February", "March",
//         "April", "May", "June", "July",
//         "August", "September", "October",
//         "November", "December"
//     ];
//
//     if(date) {
//       date = (type === 'insta') ? date * 1000 : date;
//       let c = new Date(date),
//           n = new Date();
//           diff = Math.round((n - c) / (24*60*60*1000));
//       if(diff < 1) {
//         let time_diff = Math.round((n - c) / (24*60*1000));
//         if(time_diff < 1) {
//           let hour_diff = Math.round((n - c) / (24*1000));
//           return hour_diff+"m ago";
//         } else { return time_diff+"h ago";}
//       } else if(diff == 1) {
//         return diff+" day ago";
//       } else if(diff <= 5) {
//         return diff+" days ago";
//       } else {
//         return monthNames[c.getMonth()] + ' ' +c.getDate();
//       }
//
//     } else { return null}
//   });
//
//   Handlebars.registerHelper('arrayCheck', function (index, options) {
//     if(index < 4) return options.fn(this);
//   });
//
//   Handlebars.registerHelper('getVideoID', function (url) {
//     var id = url.substring(url.lastIndexOf('/') + 1);
//     return id;
//   });
//
//   /**
//   HANDLING SOCITAL MEDIA
//   **/
//
//   const handleTwitterData = () => {
//     axios.get("js/json/twitter.json")
//     .then(function(response) {
//       var json = response.data;
//       var media = { items: json };
//       console.log(json);
//       //mediaDetail.render(media, $('#twitter-template'));
//       mediaDetail.render(json, $('#twitter-template'));
//
//     })
//     .catch(function(error) {
//       console.log(error);
//     });
//   };
//
//
//   var init = () => {
//     handleTwitterData();
//   };
//
//   init();
//
// })();
