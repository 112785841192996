$.Sq.DecisionTree = ( function( module ) {

  let _instances              = {};
  let _treeData               = {};

  const _updateEvent          = '$.Sq.Contact.contactPanelExpanded';
  const _idPrefix             = 'sq_decisiontree_';

  const _defaultOptions       = {
    treeSelector              : '.p-decision-tree',
    backBtnClass              : 'p-decision-tree__btn--back',
    listDataURL               : 'mysource_files/tree1.html',
    decisionInnerContentClass : '.p-decision-tree__content-block',
    animationSpeed            : 1,
    initialContainerWidth     : 380,
  }

  const SqDecisionTree  = function( elId, el, customOptions ) {

    const _options            = Object.assign( {}, _defaultOptions, customOptions );

    const _$el                = el;
    const _$containers        = _$el.querySelectorAll( `${_options.treeSelector}__list-container` );
    const _$innerContainer    = _$el.querySelector( `${_options.treeSelector}__inner` );
    const _$currentContainer  = _$el.querySelector( `${_options.treeSelector}__current` );
    const _$nextContainer     = _$el.querySelector( `${_options.treeSelector}__next` );
    const _$previousContainer = _$el.querySelector( `${_options.treeSelector}__previous` );

    const _url                = _$el.dataset.url;

    let tree                  = {};
    let _direction            = 1;
    let _containerWidth       = _options.initialContainerWidth;

    init();


    // Private methods

    function init() {
      // console.log( `initializing SqDecisionTree '${elId}'` );

      _$el.addEventListener( 'click', handleClick );
      window.addEventListener( _updateEvent, updateWidth );
      window.addEventListener('resize', updateWidth);

      showCurrentList(_url);
    }

    function handleClick( e ) {
      e.preventDefault();
      _direction = ( e.target.classList.contains( _options.backBtnClass )) ? -1 : 1;
      if( e.target.href && !e.target.closest(_options.decisionInnerContentClass)) { showCurrentList( e.target.href ); }
      else if(e.target.href){
        if(e.target.target == "_blank"){
          window.open(e.target.href);
        }else{
        window.location = e.target.href;
      }
     }
    }

    function handleError( e ) {
      console.log( `Error: ${e}` );
    }

    function showCurrentList( url = _options.listDataURL ) {
      fetchListData( url )
        .then( response => {
          updateTree( url, response.data );
          renderTree();
        })
        .catch( error => handleError( error ));
    }

    function fetchListData( url ) {
      return new Promise(( resolve, reject ) => {
        if( _treeData[url] ) { resolve( _treeData[url] ); }

        else {
          axios.get( url )
            .then( response => resolve( response ))
            .catch( error => reject( error ));
        }
      });
    }

    function updateTree( url, data ) {
      var treeNode = {
        url: url,
        data: data,
        previous: _direction === 1 ? _treeData.current : _treeData.current.previous,
        next: _direction === 1 ? undefined : _treeData.current
      };

      if( treeNode.previous && ( treeNode.previous.url === treeNode.url )) {
        treeNode.previous = undefined;
      }

      if( _treeData.current ) { _treeData.current.next = treeNode; }

      _treeData.current = _treeData[url] = treeNode;
    }

    function renderTree() {
      updateWidth();

      let currentNode = _treeData.current;
      let xOffset = ( _direction === 1 ) ? 0 : -( _containerWidth * 2 ) ;

      _$currentContainer.innerHTML = currentNode.data;
      _$previousContainer.innerHTML = currentNode.previous ? currentNode.previous.data : '';
      _$nextContainer.innerHTML = currentNode.next ? currentNode.next.data : '';

      TweenLite.from( _$innerContainer, _options.animationSpeed, {
        ease: Elastic.easeOut.config( .75, 1 ),
        css: { marginLeft: xOffset }
      });
    }

    function updateWidth() {
      if( !_$el.offsetWidth ) { return; }

      _containerWidth = _$el.offsetWidth;
      _$innerContainer.style.marginLeft = ( -_containerWidth + 'px' ) ;

      Array.from( _$containers ).forEach( item => item.style.width = _containerWidth + 'px' );
    }

    // Public methods

    return tree;
  }

  // Private methods

  function createInstance( elId, el, customOptions ) {
    if( _instances[elId] ) {
      throw( new Error( `Warning: DecisionTree instance ${elId} already exists!` ));
      return;
    }

    if( !el ) {
      throw( new Error( `Warning: DecisionTree element ${el} not found!` ));
      return;
    }

    _instances[elId] = SqDecisionTree( elId, el, customOptions );
  }

  // Public methods

  module.init = function( customOptions = {} ) {
    let selector = customOptions.treeSelector || _defaultOptions.treeSelector;
    let $items = document.querySelectorAll( selector );

    Array.from( $items ).forEach(( item, index ) => {
      createInstance( `${_idPrefix}${index}`, item, customOptions );
    });
  }

  return module;

})( $.Sq.DecisionTree || {} );
