/*global jQuery*/
/**
 * {{name}}
 * Global JS
 *
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * change log:
 *     {{date}} - First revision
 */

/*
 * Table of Contents
 *
 * - Global
 * - Modules
{{toc}}
 */
'use strict';
/*
--------------------
Global
--------------------
*/
//  Declare JS Enabled.
//$('body').removeClass('no-js').addClass('js-enabled');



// Polyfill for CustomEvent

(function () {

  if ( typeof window.CustomEvent === "function" ) return false;

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

// Polyfill for matches & closest

(function (ElementProto) {
  if (typeof ElementProto.matches !== 'function') {
    ElementProto.matches = ElementProto.msMatchesSelector || ElementProto.mozMatchesSelector || ElementProto.webkitMatchesSelector || function matches(selector) {
      var element = this;
      var elements = (element.document || element.ownerDocument).querySelectorAll(selector);
      var index = 0;

      while (elements[index] && elements[index] !== element) {
        ++index;
      }

      return Boolean(elements[index]);
    };
  }

  if (typeof ElementProto.closest !== 'function') {
    ElementProto.closest = function closest(selector) {
      var element = this;

      while (element && element.nodeType === 1) {
        if (element.matches(selector)) {
          return element;
        }

        element = element.parentNode;
      }

      return null;
    };
  }
})(window.Element.prototype);


// Global Variables
const $body = $('body');

$.Sq = $.Sq || {};

$( init );
function init() {
  $.Sq.Accordion.init();
  $.Sq.Menu.init();
  $.Sq.Card.init();
  $.Sq.Contact.init();
  $.Sq.DecisionTree.init();
  $.Sq.SocialTab.init();

  $('.p-quicklinks__list').masonry();
}

$.Sq.Menu = (function( module ) {
  module.toggleMenuPanel = function() {
    let $menuBtn = $('.js-menu-btn');
    let menuStatus = 'is-menu-open';

    $menuBtn.on('click', function(e) {
      e.preventDefault();

      if ($body.hasClass(menuStatus)) {
        $body.removeClass(menuStatus);
      } else {
        $body.addClass(menuStatus);

        //add focus to search input
        //wont work on iOS
        if ($(this).hasClass('p-nav__search-btn')) {
          $('.o-page-container').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
          (ev) => {
            if($(ev.target).css('transform') !== 'none' && ev.target.classList.contains('o-page-container')) {
              $('header .p-footer__search-input').focus();
            }
          });
        }
      }
    });
  }

  module.toggleSubmenuMobile = function() {
    // Init
    $('.p-nav__menu-item.is-menu-current .p-nav__submenu--mobile').show();

    // Toggle
    let $arrowBtn = $('.js-menu-accordion');

    $arrowBtn.on('click', function(e) {
      e.preventDefault();

      let $this = $(this),
          $thisParent = $this.parent(),
          $thisTarget = $($this.attr('href'));

      if ($thisParent.hasClass('is-menu-current')) {
        $thisTarget.velocity('slideUp');
        $thisParent.removeClass('is-menu-current');
      } else {
        $thisTarget.velocity('slideDown');
        $thisParent.addClass('is-menu-current');
      }
    });
  }

  function hoverState($target, action) {
    if (action === 'mouseenter') {
      $target.addClass('is-submenu-open');
    } else {
      $target.removeClass('is-submenu-open');
    }
  }

  module.hoverSubmenu = function() {
      let $firstLvlMenu = $('.p-nav__menu-link');

      $firstLvlMenu.hoverIntent(function() {
        let $this = $(this),
            $thisTarget = $($this.attr('link-data'));

        hoverState($thisTarget, 'mouseenter');
      }, function() {
        let $this = $(this),
            $thisTarget = $($this.attr('link-data'));

        hoverState($thisTarget, 'mouseleave');
      });

      // Display the bar in the first level menu on hover of submenu panel
      let $firstLvlPanel = $('.p-nav__submenu');

      $firstLvlPanel.hover(function() {
        let $this = $(this),
            $thisTarget = $('#' + $this.attr('id') + '-link');

        hoverState($thisTarget, 'mouseenter');
      }, function() {
        let $this = $(this),
            $thisTarget = $('#' + $this.attr('id') + '-link');

        hoverState($thisTarget, 'mouseleave');
      });
  }

  module.init = function() {
    // load masonry
    module.toggleMenuPanel();
    module.toggleSubmenuMobile();
    module.hoverSubmenu();
  }

  return module;
})( $.Sq.Menu || {} );

$.Sq.Card = (function( module ) {
  module.masonry = function() {
    //setTimeout(function() {
      $('.p-card--masonry').masonry({
        itemSelector: '.p-card--masonry li',
        gutter: 20
      });
    //}, 500);

    setTimeout(function() {
      $('.p-card--masonry').masonry({
        itemSelector: '.p-card--masonry li',
        gutter: 20
      });
    }, 500);
  }

  module.updateMarkup = function() {
    let $cardLink = $('.p-card a'); //$this.siblings('a');

    $cardLink.each(function() {
      let $this = $(this),
          $thisTime = $this.siblings('em');

      // wrap text inside <a>
      $this.wrapInner( '<span></span>' );

      // move time in <a>
      $thisTime.prependTo($this);
    });
  }

  module.timeago = function() {
    var cardTime = $('.p-card--timeago em');

    cardTime.each(function() {
      let $this = $(this);

      $this.text( $.timeago( $this.text()
        .replace('Tuesday','tuesday')
        .replace('Thursday','thursday') )
      .replace('about', '') );
    });
  }

  module.loadmore = function() {
    // Init
    const $cardBtn = $('.js-card-btn');
    const $cardCount = $('#card-count');
    let fromIndexInit = 0,
        totalIndex = 0;

    $cardBtn.each(function() {
      let $this = $(this);

      totalIndex = $this.parent().prev().find('li').length;
      fromIndexInit = parseFloat($this.attr('data-init')) - 1;
    });

    //let $initHide = $('.p-card li:nth-child(n+' + fromIndexInit + ')');
    let $initHide = $('.p-card li:gt(' + fromIndexInit + ')');

    if (fromIndexInit !== 0) {
      $initHide.hide();
    }

    $('.p-card--masonry').masonry('layout');

    // on Click
    $cardBtn.on('click', function(e) {
      e.preventDefault();

      let $this = $(this),
          $thisTarget = $this.parent().prev();
      let fromIndex = parseFloat($this.attr('data-init')),
          countIndex = parseFloat($this.attr('data-count')),
          toIndex = fromIndex + countIndex,
          nextIndex = toIndex + 1,
          cardCount = nextIndex - 1;

      //$this.hide();

      $thisTarget.find('li').slice(fromIndex, toIndex).velocity('fadeIn', {
        complete: function() {
          $('.p-card--masonry').masonry('layout');
          //$this.show();
          $this.attr('data-init', nextIndex);
          $cardCount.html(cardCount);

          if (toIndex >= totalIndex) {
            $this.hide();
          };
        }
      });
    });
  }

  module.init = function() {
    // load masonry
    module.masonry();
    module.updateMarkup();
    module.timeago();
    module.loadmore();
  }

  return module;
})( $.Sq.Card || {} );


$.Sq.Contact = (function( module ) {
  module.expandEvent = new CustomEvent('$.Sq.Contact.contactPanelExpanded');

  module.togglePanel = function() {
    let $contactBtn = $('.js-toggle-contact');

    $contactBtn.on('click', function(e) {
      e.preventDefault();

      let $this = $(this),
          $thisTarget = $($this.attr('href'));

      if ($this.hasClass('is-contact-open')) {
        // collapse
        $thisTarget.velocity('slideUp');
        $this.removeClass('is-contact-open');
      } else {
        // expand
        $thisTarget.velocity('slideDown');
        $this.addClass('is-contact-open');

        setTimeout( () => window.dispatchEvent( module.expandEvent ), 100 );
      }
    });
  }

  module.closePanel = function() {
    let $contactCloseBtn = $('.js-contact-close');

    $contactCloseBtn.on('click', function(e) {
      e.preventDefault();

      let $this = $(this),
          $thisTarget = $($this.attr('href')),
          $contactBtn = $('.js-toggle-contact');

      $thisTarget.velocity('slideUp');
      $contactBtn.removeClass('is-contact-open');
    });
  }

  module.init = function() {
    // load masonry
    module.togglePanel();
    module.closePanel();
  }

  return module;
})( $.Sq.Contact || {} );

$.Sq.SocialTab = (function( module ) {
  module.toggleTab = function() {
    const $tabBtn = $('.p-socialmedia__link'),
          $tabItems = $('.p-socialmedia__item'),
          $tabSection = $('.p-socialmedia__section');

    $tabBtn.on('click', function(e) {
      e.preventDefault();

      let $this = $(this),
          $thisItem = $this.parent(),
          $thisTarget = $($this.attr('href'));

      $tabItems.removeClass('is-tab-active');
      $thisItem.addClass('is-tab-active');

      $tabSection.removeClass('is-tab-active');
      $thisTarget.addClass('is-tab-active');

      // ARIA
      $tabBtn.attr('aria-selected', false);
      $this.attr('aria-selected', true);

      $tabSection.attr('aria-hidden', true);
      $thisTarget.attr('aria-hidden', false);

      // if ($thisItem.hasClass('is-tab-active')) {
      //   $thisItem.removeClass('is-tab-active');
      // } else {
      //
      // }
    });
  }

  module.init = function() {
    module.toggleTab();
  }

  return module;
})( $.Sq.SocialTab || {} );

$( document ).ready(function() {
  var thisForm = $("#search_page_464398");

  $('.p-home__find-search').on('submit', "#search_page_464398", function( event ) {
    event.preventDefault();

    var formData = new FormData($(this)[0]);
    var submitButton = $(this).find('input[type="submit"]'),
        submitButtonParam = submitButton.attr('name');

    formData.append(submitButtonParam, "Submit");

    var submitUrl = $(thisForm).attr("action") + "?queries_query_query=" + $("#search_page_464398 #queries_query_query").val();

    $.ajax({
        url: submitUrl,
        type: 'POST',
        data: formData,
        async: true,
        success: function(data) {
            $(".p-home__find-search").html(data);
        },
        cache: false,
        contentType: false,
        processData: false
    });
  });
});

( function () {
    
  //hide/display google translate panel;
  $('.p-topnav__link').on('click', function(e) {
    if($('#google_translate_element').offset() != undefined) {
      var $left = $('#google_translate_element').offset().left;
      $('iframe.goog-te-menu-frame').toggle();
      $('iframe.goog-te-menu-frame').css('top','56px');
      $('iframe.goog-te-menu-frame').css('left',$left);
    } else { return }
  });

  $(document.body).click(function(e){
    var $box = $('iframe.goog-te-menu-frame');
    if(e.target.id !== 'google_translate_element') {
      $box.hide();  
    }
  });
  
  //add class is-current on RHC fist list itme when page is news detail page;
  function addClass() {
    let pathname = window.location.pathname,
    assetId = $('.c-section-nav__title').attr('asset-id');

    let array = pathname.split('/'),
    lastsegment = array[array.length-1];

    if((lastsegment.indexOf("news_article") > -1) || (lastsegment.indexOf("news") > -1)) {
      if(assetId === '460994') {
        $( ".c-section-nav .c-section-nav__item" ).first().addClass( "is-current" );
      }  
    } 
  };
  
  //use AJAX function to display sub mega menu;
  function listMegaMenu() {
    $.ajax({
      url: "//www.police.nsw.gov.au/_integration/mega_menu/nested_mega_menu_-_wrapper",
      cache: false,
      success: function(html){
        $(".submenu-container").append(html);
      }
    });
  };
  
  //replace font style on window system
  function replaceFont() {
    if (navigator.userAgent.toLowerCase().indexOf("windows") !== -1) { 
      $('body').addClass('is-windows');      
      if (navigator.userAgent.toLowerCase().indexOf("chrome") !== -1) { 
        $('body').addClass('is-chrome');  
      } 
    }
    if (navigator.userAgent.toLowerCase().indexOf("firefox") !== -1) {
      $('body').addClass('is-firefox'); 
    }
  }

  replaceFont();
  listMegaMenu();
  addClass();
})();

/*
--------------------
Modules
--------------------
*/
