(function() {
  const addImgCaption = () => {
    //collect all image elements with class c-img-caption
    const cImgCaptions = document.querySelectorAll('img[title]');

    if(!cImgCaptions) return;

    for(let i = 0; i < cImgCaptions.length; i++) {

      let cImgCaption   = cImgCaptions[i],
          imgFigure     = document.createElement('figure'),
          imgFigcaption = document.createElement('figcaption'),
          imgCaption    = cImgCaption.getAttribute('title'),
          imgStyle      = cImgCaption.getAttribute('style'),
          imgClasses    = cImgCaption.getAttribute('class');
          //imgWidth      = cImgCaption.width;

      imagesLoaded( cImgCaption, function() {
        let imgWidth = cImgCaption.width;

        if(imgCaption) {
          imgFigure.setAttribute('style', imgStyle);
          imgFigure.setAttribute('class', imgClasses);
          imgFigure.style.width = imgWidth + 'px';
          cImgCaption.removeAttribute('style');
          cImgCaption.parentNode.appendChild(imgFigure);
          imgFigure.appendChild(cImgCaption);
          imgFigure.appendChild(imgFigcaption);
          imgFigcaption.innerText  = imgCaption;

          // move figure before non-markedup paragraph
          $(imgFigure).prependTo(imgFigure.parentNode);
        };
      });
    };
  };

  const init = () => {
    addImgCaption();
  };

  init();
})();
