(function() {
  $.fn.customLightbox = function(config) {
    var $html = $('html'),
      $body = $('body'),
      closeSelector = '.js-lightbox-close',
      containerSelector = '.js-lightbox-container',
      $current,
      mqDesktop = 'screen and (min-width: 64em)',

      defaultConfig = {

      };

    $.extend(defaultConfig, config);

    var beforeShowup = defaultConfig.beforeShowup,
      afterShowup = defaultConfig.afterShowup,
      beforeClose = defaultConfig.beforeClose,
      afterClose = defaultConfig.afterClose;

    this.each(function() {
      var targetId = $(this).attr('data-target'),
        $lightboxClose = $(closeSelector);

      $(this).bind('click', function(e) {
        e.preventDefault();
        var $target = $('#' + targetId);

        // HOOK: beforeShowup
        if (typeof beforeShowup === 'function') {
          beforeShowup.call(this);
        }

        if ($current !== undefined && $current.hasClass('is-active') && !$(this).hasClass('js-lightbox-close')) {
          $current.removeClass('is-active');
        }

        $current = $target;
        if (!window.matchMedia(mqDesktop).matches) {
          $target.removeClass('hidden');
          $target.siblings().addClass('hidden');
        } else {
          $body.addClass('u-overflow-hidden');
          !window.matchMedia(mqDesktop) && $html.addClass('u-overflow-hidden');
        }
        $target.toggleClass('is-active');

        // HOOK: afterShowup
        if (typeof afterShowup === 'function') {
          afterShowup.call(this);
        }
      });

      //$lightboxClose.each(function() {
        $lightboxClose.bind('click', function(e) {
          e.preventDefault();

          // HOOK: beforeClose
          if (typeof beforeClose === 'function') {
            beforeClose.call(this);
          }

          $(this).closest(containerSelector).removeClass('is-active');
          $(this).closest(containerSelector).siblings().removeClass('hidden');
          $body.removeClass('u-overflow-hidden');
          !window.matchMedia(mqDesktop) && $html.removeClass('u-overflow-hidden');

          // HOOK: afterClose
          if (typeof afterClose === 'function') {
            afterClose.call(this);
          }
        });
      //});

    });

    return this;
  }
})();

$(document).ready(function() {

  $('#feedback-survey-trigger').magnificPopup({
    type: 'inline',
  });


  /*
   * set cookie
   *
   * @param [string] cname            [cookie name]
   *        [string/number..] cvalue  [cookie value]
   *        [number] exdays           [cookie expire days]
   */
  var setCookie = function(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var expires = 'expires=' + d.toUTCString();

      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    },

    /*
     * get cookie
     *
     * @param [string] cname              [cookie name]
     * @return [string/number ...] cvalue [cookie value]
     */
    getCookie = function(cname) {
      var name = cname + '=';
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },

    /*
     * has cookie
     *
     * @param [string] cname              [cookie name]
     * @return [bool] true/false
     */
    hasCookie = function(cname) {
      var cvalue = getCookie(cname),
        result = false;

      if (cvalue !== '') {
        result = true;
      }
      return result;
    };

  // #.Ajax form
  var urlDomId = 'contact-form-url',
    $ajaxFormContainer = $('.js-ajax-form-container'),
    hasAjaxLoaderClass = 'u-has-ajax-loader',
    ajaxLoaderClass = '.u-ajax-loader',
    bindAjaxToSubmit = function(url, $container) {
      if ($container.find('form *[type=submit]').length > 0) {
        $container.find('form *[type=submit]').on('click', {
          container: $container
        }, function(e) {
          e.preventDefault();
          var data = $(this).closest('form').serialize() +
            '&' + $(this).attr('name') + '=' + $(this).val();
          submitContactForm(url, data, $container);
        });
      }
    },
    bindToCloseButton = function() {
      if($('.c-lightbox__close-button.js-lightbox-close').length > 0) {
        $('.c-lightbox__close-button.js-lightbox-close').bind('click', function(event) {
          event.preventDefault();
          var containerSelector = '.js-lightbox-container';
          var $html = $('html'),
              $body = $('body'),
              mqDesktop = 'screen and (min-width: 64em)';
          $(this).closest(containerSelector).removeClass('is-active');
          $(this).closest(containerSelector).siblings().removeClass('hidden');
          $body.removeClass('u-overflow-hidden');
          !window.matchMedia(mqDesktop) && $html.removeClass('u-overflow-hidden');
        });
      }
    },
    submitContactForm = function(url, data, $container) {
      $.ajax({
        type: 'POST',
        url: url,
        data: data,
        container: $container

      }).done(function(data, msg) {
        if (msg === 'success') {
          var $container = this.container;
          $container.empty().append(data);
          //packageSelectBox();
          bindAjaxToSubmit(url, $container);
          bindToCloseButton();
          //formValidate();
        }     
      });
    },
    ajaxFormFunc = function($formContainer, url) {
      $.ajax({
        type: 'GET',
        url: url,
        container: $formContainer

      }).done(function(data, msg) {
        if (msg === 'success') {
          var $container = this.container;

          $container.removeClass(hasAjaxLoaderClass);
          $container.find(ajaxLoaderClass).remove();
          $container.append(data);
          // add wrap to normal Select box
          //packageSelectBox(); // predefined above
          bindAjaxToSubmit(url, $container);
          //formValidate();
        }
      });
    };

  // #. Online survey
  var fsCookieName = 'feedback_survey_timer',
    fsBlockerCookieName = 'feedback_survey_blocker',
    fsExDays = 1,
    interval = 5000,

    fsData = JSON.parse($('#feedback-survey-data').html() || '{}'),
    //_fsFireTime = parseFloat(fsData.fire_time, 10),
    _fsFireTime = fsData.fire_time,
    fsFireTime = isNaN(_fsFireTime) ? 2000 : _fsFireTime; // in minute

  // set timer/blocker for feedback survey
  (function() {
    if (hasCookie(fsBlockerCookieName)) {
     // console.log('has blocker');
     // setCookie(fsBlockerCookieName, '');
      return;
    }
    if (!hasCookie(fsCookieName)) {
      setCookie(fsCookieName, "0", fsExDays);
    }

    if( !($('.feedback_survey').length) ) {
      return;
    }



    if( $('.feedback_survey').length ) {
      var surveyInterval = setTimeout(function() {
        var _timerValue = parseFloat(getCookie(fsCookieName), 10),
          timerValue = isNaN(_timerValue) ? 0 : _timerValue;

          console.log(timerValue, fsFireTime);
        if (timerValue > fsFireTime - 1 && !hasCookie(fsBlockerCookieName)) {
          $('#feedback-survey-trigger').trigger('click');

          // set cookie to block survey lightbox shows up
          setCookie(fsBlockerCookieName, 'true', fsExDays);
          return;
        }

        if (!hasCookie(fsBlockerCookieName)) {
          setCookie(fsCookieName, fsFireTime, fsExDays);
        }
      }, interval);
    }
  })();

  var ajaxFeedbackSurvey = function() {
      var fsUrl = $('.feedback_survey').attr('data-survey-url');
      console.log(fsUrl);
      var $container = $('#lightbox-feedback-survey .lightbox__content');
      ajaxFormFunc($container, fsUrl);
    },
    afterClose = function() {
      // erase the form
      $('#lightbox-online-survey .c-lightbox__desc').empty();
    };

  //setCookie(fsBlockerCookieName, 'true', fsExDays);
  $('#feedback-survey-trigger').customLightbox({
    afterShowup: ajaxFeedbackSurvey,
    afterClose: afterClose
  });
});