// //*-- Accordion --*//
// var $sectionNavItem = $('.c-section-nav__item');
//
// $sectionNavItem.each(function() {
//   var $this = $(this),
//       $thisContent = $this.children('.c-section-nav__content');
//
//   // init on page load
//   if ($this.hasClass('is-section-nav-active')) {
//     // do nothing
//   } else {
//     // hide content
//     $thisContent.hide();
//   }
// });
//
// var $sectionNavBtn = $('.c-section-nav__btn');
// $sectionNavBtn.on('click', function(e) {
//   if (Modernizr.mq('only screen and (max-width: 959px)')) {
//     e.stopPropagation();
//     e.preventDefault();
//
//     var $this = $(this),
//         $thisContent = $this.parent().next('.c-section-nav__content'),
//         $thisItem = $this.closest('.c-section-nav__item'),
//         $navItem = $('.c-section-nav__item');
//
//   // if ($thisItem.hasClass('is-section-nav-active')) {
//   //   $thisContent.velocity('slideUp');
//   //   $thisItem.removeClass('is-section-nav-active');
//   // } else {
//   //   $thisContent.velocity('slideDown');
//   //   $thisItem.addClass('is-section-nav-active');
//   // }
//
//
//     if ($thisItem.hasClass('is-current')) {
//       $navItem.velocity('slideDown');
//     } else {
//       window.location.href = $this.attr('href');
//     }
//   }
// });
//
// $(window).on('click touchstart', function() {
//   if (Modernizr.mq('only screen and (max-width: 959px)')) {
//     let $navItem = $('.c-section-nav__item:not(.is-current)');
//
//     $navItem.velocity('slideUp');
//   }
// });
// if (Modernizr.mq('only screen and (max-width: 959px)')) {
  /*let $sectionNavBtn = $('.c-section-nav__btn');

  $sectionNavBtn.on('touchstart', function(e) {
    e.stopPropagation();

    let $this = $(this),
        $thisNav = $this.closest('.c-section-nav');

    if ($thisNav.hasClass('is-expanded')) {
      // collapse
      window.location.href = $this.attr('href');
      $thisNav.removeClass('is-expanded');
    } else {
      // expand
      e.preventDefault();

      $('.c-section-nav__item').velocity('slideDown');
      $('.c-section-nav__title').velocity('slideDown');
      $thisNav.addClass('is-expanded');
    }
  });

  $(window).on('touchstart', function() {

    let $navItem = $('.c-section-nav__item:not(.is-current)');

    $navItem.velocity('slideUp');
    $('.c-section-nav__title').velocity('slideUp');

    $('.c-section-nav').removeClass('is-expanded');
  });

  // close section nav
  let $closeSectionNav = $('.js-close-section-nav');

  $closeSectionNav.on('touchstart', function(e) {
    e.stopPropagation();
    e.preventDefault();

    let $this = $(this),
        $thisNav = $this.closest('.c-section-nav');

    $('.c-section-nav__item:not(.is-current)').velocity('slideUp');
    $('.c-section-nav__title').velocity('slideUp');
    $thisNav.removeClass('is-expanded');
  })*/

//   $('h2.c-section-nav__title, .js-close-section-nav').on('touchstart', function (e) {
//     e.stopPropagation();
//     e.preventDefault();
//     let $this = $(this),
//         $thisNav = $this.closest('.c-section-nav');

//     $thisNav.toggleClass('is-expanded');
//     $('.c-section-nav__item ').toggle('600');
//   });
// }

// $(window).resize(function(){
//   if (Modernizr.mq('only screen and (max-width: 959px)')) {
//     if($('.c-section-nav').hasClass('is-expanded')) {
//       $('.c-section-nav__item ').show();
//     } else {
//       $('.c-section-nav__item ').hide();
//     }
//   }
//   if (Modernizr.mq('only screen and (min-width: 960px)')) {
//     $('.c-section-nav__item ').show();
//   }
// });

const disableExpandSectionNav = () => {
  $('h2.c-section-nav__title, .js-close-section-nav').off('touchstart mousedown');
};

const enableExpandSectionNav = () => {
  disableExpandSectionNav();

  $('h2.c-section-nav__title, .js-close-section-nav').on('touchstart mousedown', (e) => {
    e.stopPropagation();
    e.preventDefault();

    $('.c-section-nav').toggleClass('is-expanded');
    $('.c-section-nav__item ').toggle('600');
  });
};

$(window).on('load resize', (event) => {
  if (event.type == 'load') {
    $('.js-close-section-nav').on('click', (e) => {
      e.preventDefault();
    })
  }

  if (Modernizr.mq('only screen and (max-width: 959px)')) {
    if($('.c-section-nav').hasClass('is-expanded')) {
      $('.c-section-nav__item ').show();
    } else {
      $('.c-section-nav__item ').hide();
    }

    enableExpandSectionNav();
  }

  if (Modernizr.mq('only screen and (min-width: 960px)')) {
    $('.c-section-nav').removeClass('is-expanded')
    $('.c-section-nav__item ').show();

    disableExpandSectionNav();
  }
});
