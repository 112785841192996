$.Sq = $.Sq || {};

$.Sq.AjaxForm = ( function( module ) {

  // Static properties

  var _instances = {};

  const DEFAULT_OPTIONS = {
      submitSelector      : 'input[type="submit"]',
    };

  var SqAjaxForm = function( elId, element, customOptions = {} ) {

    var form        = {};

    // Private variables

    var _id         = elId;
    var _el         = element;
    var _options    = _.merge( {}, DEFAULT_OPTIONS, customOptions );
    var _submitBtn  = document.querySelector( _options.submitSelector );

    // Error checks

    if( !_el ) { throw( new Error( `Error: cannot find form '${_id}'` ) ); }

    _el.onsubmit = submitHandler;

    // Private methods

    function submitHandler( e ) {
      e.preventDefault();
      console.log( `AjaxForm: submitting '${_id}'` );

      let formData = new FormData( this );
      formData.append( _submitBtn.name, _submitBtn.value );

      axios.post( this.getAttribute( 'action' ), formData )
        .then( response => { responseHandler( response ); })
        .catch( error => { errorHandler( error ); });
    }

    function responseHandler( response ) {
      console.log( `AjaxForm: successfully submitted '${_id}'` );
      console.log( response );
      _el.innerHTML = response.request.response;
    }

    function errorHandler( error ) {
      console.log( `AjaxForm: error submitting '${_id}': ${error}` );
    }

    // Public methods

    form.getId = function() {
      return _id;
    }

    form.getElement = function() {
      return _el;
    }

    return form;
  }

  // Module private methods

  function createInstance( elId, el, customOptions ) {
    if( _instances[elId] ) { throw( new Error( `Error: Ajax form '${elId}' already exists` ) ); }
    _instances[elId] = SqAjaxForm( elId, el, customOptions );
  }

  // Module public methods

  module.init = function( selector = 'form', customOptions ) {
    let forms = document.querySelectorAll( selector );
    Array.from( forms ).forEach(( item, index ) => {
      createInstance( `form_${index}`, item, customOptions );
    });
  }

  module.getInstances = function() {
    // return instances as an array
    return Object.keys( _instances ).map(( key ) => _instances[key] );
  }

  return module;

} )( $.Sq.AjaxForm || {} );
